
.description {
    font-size: medium;
    font-style: inherit;
    font-family: 'Telegraf', sans-serif;
    text-align: left;
    height: 100vh;

}


.description h1{
    font-family: 'Telegraf', sans-serif;
    
}

.reqsubmit {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    padding-bottom: 1rem;

}

.successText{
  font-weight: bold;
  color: #41B3A3;
  text-align: left;
  
}
.control {
    margin: 1rem 0;
  }
  
  .control label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-align: left;
  }
  
  .control input,
  .control textarea {
    display: block;
    width: 100%;
    font: inherit;
    padding: 0.2rem;
    border-radius: 12px;
    border: 1px solid #ccc;
  }
  
  .control input:focus,
  .control textarea:focus {
    outline: none;
    border-color: #230052;
  }
  .actions button {
    cursor: pointer;
    font: inherit;
    color: white;
    background-color: #950740;
    border: 1px solid #950740;
    border-radius: 4px;
    padding: 0.5rem 2.5rem;
    width: 100%;
  }
  
  .actions button:hover {
    background-color: #1A1A1D;
    border-color: #1A1A1D;
  }
  