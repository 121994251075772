
.main{
  padding-top: 70px;
}
.title {
  text-align:center;
  font-family: 'Telegraf', sans-serif;
  font-size: large;
}

.auth {
  margin: 3rem auto;
  width: 95%;
  max-width: 25rem;
  border-radius: 6px;
  background-color: #557A95;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  padding-top: 50px;
  text-align: center;
}

.auth h1 {
  text-align: center;
  color: white;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  color: white;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.control input {
  font: inherit;
  background-color: #f1e1fc;
  color: #38015c;
  border-radius: 4px;
  border: 1px solid white;
  width: 100%;
  text-align: left;
  padding: 0.25rem;
}

.actions {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #950740;
  border: 1px solid #950740;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.actions button:hover {
  background-color: #1A1A1D;
  border-color: #1A1A1D;
}

.actions .toggle {
  margin-top: 1rem;
  background-color: transparent;
  color: #9f5ccc;
  border: none;
  padding: 0.15rem 1.5rem;
}

.actions .toggle:hover {
  background-color: transparent;
  color: #ae82cc;
}



.login_loader {
  position: absolute;
  top: 50%;
  margin-top: -25px;
}


.error {
  text-align:center;
  font-family: 'Telegraf', sans-serif;
  font-size: medium;
  font-weight: bold;
  color: #eee2dc;
}

.w50{
  color: #f1e1fc;
  width: 50%;
  text-align : center;
  justify-content: center;
}


.w50:hover{
  color: #f1e1fc !important;
  width: 50%;
  text-align : center;
  font-weight: bold;
}