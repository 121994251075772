
.form {
  margin: 1rem auto;
  width: 90%;
  max-width: 40rem;
  text-align: center;
  background-color: white;
  padding: 2rem;
  border-radius: 12px;
}


.controls {
  margin: 1rem 0;
}

.controls label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-align: left;
}

.controls input,
.controls textarea {
  display: block;
  width: 100%;
  font: inherit;
  padding: 0.2rem;
  border-radius: 12px;
  border: 1px solid #ccc;
}

.controls input:focus,
.controls textarea:focus {
  outline: none;
  border-color: #230052;
}


.actions button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #950740;
  border: 1px solid #950740;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
  width: 100%;
}

.actions button:hover {
  background-color: #1A1A1D;
  border-color: #1A1A1D;
}

.titl {
  text-align:center;
  font-family: 'Telegraf', sans-serif;
  font-size: large;
}


.titldescr {
  text-align:center;
  font-family: 'Telegraf', sans-serif;
  font-size: large;
}

.titleSuccess {
  text-align:center;
  font-family: 'Telegraf', sans-serif;
  font-size: x-large;
  font-weight: bold;
  color : #1F6521;
  margin-left: 20%;
  
}

.titlerror {
  text-align:center;
  font-family: 'Telegraf', sans-serif;
  font-size: x-large;
  font-weight: bold;
  color : red;
  margin-left: 20%;

}

.captch > div {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.captch > div > canvas {
  margin-right: 10px;
}
