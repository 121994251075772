.float-container {
    padding: 0px;
    height: 0%;
    background-color: lawngreen;
}

.float-child {
    width: 10%;
    float: left;
    padding: 20px;
    height: 2vh;
}  

.float-child img{
    opacity: 1;
    width:30px;
    float: right;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    transition: .2s ease;
   
}

.float-child img:hover {
    opacity: 0.7;  
    filter:drop-shadow(8px 8px 10px gray);
    transition: .7s ease;
}

