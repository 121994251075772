


.aisppisp {
    height: 100vh;
    width: 70vh;
    padding-top: 4vh;
}

.spanbold{
    font-weight: bolder;
    color: #222629;
    font-weight: bold;
    font-weight: 14px;

}

.spanboldsub{
    text-align: justify;
    color: #222629;
}

.heightSpacing{
    margin-bottom: 20px;
}