.wittytitle{
    margin-top: 20px;
}
.title{
    text-align: center;
    font-size: 25px;
}

.logo img{
    width: 80px;
}