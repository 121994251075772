
.description {
    font-size: medium;
    font-style: inherit;
    font-family: 'Telegraf', sans-serif;
    text-align: left;
    height: 100vh;

}


.description h1{
    font-family: 'Telegraf', sans-serif;
    
}