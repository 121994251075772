.App {
  text-align: center;
}
.balance {
  height: 100vh;
  padding-top: 4px;
}
.right-content {
  background-color: black !important;
  height: 100vh;
  color: blanchedalmond;
  width: 100%;
  padding-top: 4px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.textTricks {
  font-weight: bolder;
  font-size: larger;
  /* color: black; */
  align-content: center;
}

.textTricksResponse {
  font-weight: bolder;
  font-size: larger;
  color: #c2b9b0;
  align-content: center;
  margin-left: 10px;
}
.urlPlace {
  width: 70%;
  pointer-events: none;
  border-style: hidden;
  margin-left: 10px;
}

.textFamily {
  font-family: 'Telegraf', sans-serif;
  font-size: medium;
  font-weight: bold;
}

.textFamilyMessage {
  font-family: 'Telegraf', sans-serif;
  font-size: medium;
  font-weight: bold;
  font-style: italic;
  color: #1a1a1d;
}

.alignment button {
  display: inline;
  padding: 10px 20px;
  border-radius: 4px;
  margin: 10px;
  color: #f64c72;
  font-weight: bold;
}
.status200 {
  background-color: #659dbd;
}

.status400 {
  background-color: #659dbd;
}

.status500 {
  background-color: #659dbd;
}

.textTricksError {
  font-weight: bolder;
  font-size: larger;
  color: #fc4445;
  align-content: center;
}

.balance {
  height: 100vh;
}

table {
  font-family: 'Telegraf', sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.mb100 {
  margin-bottom: 100px;
}

.mb150 {
  margin-bottom: 150px;
}

.response-container {
  margin-top: 10px;
}

.response-container > h3 {
  color: #000;
}

.text-content {
  text-align: justify;
}