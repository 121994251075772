.left {
    width: 90%;
    line-height: 45px;
    margin: 1rem auto;
    text-align: left;
    font-size: small;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
    list-style: none;
    padding: 1rem;
    font-family: 'Telegraf', sans-serif;
}
.image{
    vertical-align: top;
    width:3px;
    right: 20px;
    align-items: flex-end;
    transition: 0.2s ease;
}

li :hover {
    color: #379683;
    transition: 0.4s ease;
}

li a{
    color: #1A1A1D;
}


li a:hover{
    color: #379683;
}

li button {
    pointer-events: none;
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 1px 12px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 10px;
  }



  
.active {
    color: red;
}

.textToMoveMain{
    padding:0 10px
}
.textleftmove{
    padding: 0px 16px;
}
