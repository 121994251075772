
.layout {
    background-color: whitesmoke;
    vertical-align: top;
    height: 100vh;
    overflow-y: scroll;
    position: fixed !important;
    width:100%;
    padding-top: 0px;
}

.logout{
    width:40px
}

.bg-black {
    background-color: #000;
}