
.description {
    font-size: medium;
    font-style: inherit;
    font-family: 'Telegraf', sans-serif;
    text-align: left;
    height: 100vh;

}


.description h1{
    font-family: 'Telegraf', sans-serif;
    
}

.textAdjustment {
    position: absolute;
    text-align: center;
    padding-top: 60px;
}

.textAdjustment h1 {
    text-align: left;
}

.textAdjustment p{
    width:100%;
    display: flex;
    text-align: justify;
    width: 96%;
}