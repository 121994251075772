

.sandbox {
    height: 100vh;
    width: 70%;
    align-content: center;
    padding-top: 4vh;
}

.spacing{
    color: #222629;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
}

.spacingtext{
    color: #222629;
    text-align: left
}