.home {
    /*background: #fff;*/  /* fallback for old browsers */
    /*background: -webkit-linear-gradient(to right, #6E48AA, #9D50BB); */ /* Chrome 10-25, Safari 5.1-6 */
    /*background: linear-gradient(to right, #6E48AA, #9D50BB);*/ /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: #fff;
    color: #4a4a4a;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 12px;
  }
  
  .home .title {
    font-size: 2.4rem;
    text-align: center;
  }
  
  .home .descript{
      font-size: 1.4rem;
  }
  
  .home .docs-button {
    border: 2px solid #4a4a4a;
    border-radius: 10px;
    /* color: #fff; */
    padding: 12px 32px;
    transition: all .3s linear;
    margin-left: 30px;
    /* background: #1105D2; */
  }
  
  .home .docs-button:hover {
    color: #1105D2;
    border: 2px solid #1105D2;
    opacity: 1;
  }
  
  
  .home__logo {
    position: absolute;
    right: 10%;
    top: 10%;
  }
  